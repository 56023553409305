@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1500px) {
        @content;
    }
}

.page {
    display: grid;
    grid-template-areas:
            'sidebar header'
            'sidebar content';
    grid-template-columns: 350px 1fr;
    column-gap: 44px;
    row-gap: 32px;

    @include medium-breakpoint {
        grid-template-columns: 300px 1fr;
    }

    @include media-tablet {
        grid-template-areas:
                'header'
                'sidebar'
                'content';
        grid-template-columns: 100%;
    }

    @include media-mobile {
        row-gap: 20px;
    }
}

.no-visible {
    opacity: 0;
}
